import 'lazysizes';

export default new Promise((resolve, reject) => {
    Promise.all([
        import('../utilities/loadAlpine')
    ]).then((modules) => {
        let ready = (cb) => {
            if (document.readyState != "loading") cb();
            else document.addEventListener("DOMContentLoaded", cb());
        }

        ready(() => {
            // Page Specific Code Here
            let isMobile = window.innerWidth < 769 ? true : false;

            if (isMobile) {
                import('@js/components/mobileMenu').then(module => {
                    let mobileMenu = module.default;
                    mobileMenu()
                }).then(() => {
                    let header = document.querySelector('#header-main');
                    header.querySelector('.header-menu').classList.add('mobile')
                    header.setAttribute('x-data', header.getAttribute('defer-x-data'));
                    window.Alpine.initializeComponent(header);
                });
            }

            const loadTestimonials = () => {
                import('@js/components/testimonials').then(module => {
                    let testimonials = module.default;
                    testimonials()
                }).then(() => {
                    let slider = document.querySelector('[defer-x-data="testimonialSlider()"]');
                    slider.setAttribute('x-data', slider.getAttribute('defer-x-data'));
                    window.Alpine.initializeComponent(slider);
                })
            }

            const loadFAQs = () => {
                import('@js/components/frequent-questions').then(module => {
                    let questions = module.default;
                    questions();
                }).then(() => {
                    let faqs = document.querySelector('[defer-x-data="frequentQuestions()"]')
                    faqs.setAttribute('x-data', faqs.getAttribute('defer-x-data'));
                    window.Alpine.initializeComponent(faqs);
                });
            }

            if (window.IntersectionObserver) {
                let testimonialsObserver = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            loadTestimonials()
                            testimonialsObserver.unobserve(entry.target)
                        }
                    })
                }, {rootMargin: "0px 0px 400px 0px"});

                testimonialsObserver.observe(document.querySelector('[defer-x-data="testimonialSlider()"]'));

                let faqsObserver = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if(entry.isIntersecting) {
                            loadFAQs()
                            faqsObserver.unobserve(entry.target)
                        }
                    })
                }, {rootMargin: "0px 0px 400px 0px"});

                faqsObserver.observe(document.querySelector('[defer-x-data="frequentQuestions()"]'))
            }
            else {
                loadTestimonials()
                loadFAQs()
            }

        });

        return resolve();
    })
    .catch(reject);
})